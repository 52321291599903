.home {
    width: 100%;
    align-items: center;
    font-family: "DM Sans";
    color: var(--bgcolour);
  } 
  .about {
    width: 100%;
    height: calc(100vh - 100px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: var(--bgcolour);
    color: #f0f0f0;
  }
  
  .about h2 {
    font-family: 'Noto Sans SC Variable', sans-serif;
    font-weight: 700;
    font-size: 80px;
    color: var(--coral);
    height: 80px;
    align-items: center;
    margin-top: 40px;
    margin-bottom: 35px;
  }
  img {
    display: block;
    margin: 0 auto;
    object-fit: cover; 
  }
  .self {
    width: calc((100vh - 100px) / 2.5);
    height: calc((100vh - 100px) / 2.5);
    position: relative;
    overflow: hidden;
    border-radius: 50%;
    outline-style: inset;
    outline-width: 15px;
    outline-color: var(--coral);
  }
  .about .prompt {
    color:#f0f0f0;
    width: 40%;
    font-size: 30px;
  }
  .prompt svg {
    font-size: 60px;
    margin: 5px;
    color: white;
  }
  
  /* .skills {
    font-size: 35px;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  } */
  
  /* .skills h1 {
    color: var(--bgcolour);
    align-items: center;
    justify-content: center;
    font-family: var(--bigfont);
  }
  
  .skills .list {
    list-style: none;
    width: 60%;
    font-family: var(--big);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  
  .list span {
    font-size: 20px;
    text-align: center;
  } */
  @media only screen and (max-width: 876px) {
    .about h2 {
      margin-top: 35px;
      font-size: 50px;
      margin-bottom: 15px;
    }
    .about .prompt {
      margin-top: 0px;
      font-size: 25px;
      width: 80%;
    }
  }

  
  @media only screen and (max-width: 600px) {
    .about h2 {
      font-size: 35px;
      margin-top: 15;
      margin-bottom: 0px;
      padding:0px;
    }
  
    .about .prompt {
      margin-top: 0px;
      padding-top: 0px;
      font-size: 20px;
      width: 80%;
    }
    .self {
      width: 200px;
      height: 200px;
    }
/*   
    .skills {
      text-align: center;
    }
    .list {
      padding: 0;
    }
  
    .skills h2 {
      font-size: 30px;
    } */
  }
  