
body { 
    margin: 0px; 
    padding: 0px; 
}
.footer {
    width: 100%;
    height: 200px;
    background: var(--bgcolour);
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "DM Sans";
    flex-direction: column;
    padding: 0;
  }
  
  .footer p {
    color: white;
  }
  
  @media only screen and (max-width: 600px) {
    .footer svg {
      font-size: 50px;
    }
  }
  