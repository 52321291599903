.about {
    width: 100%;
    align-items: center;
    font-family: "DM Sans";
    color: var(--bgcolour);
  } 
  .ctr {
    width: 100%;
    height: calc(100vh - 100px);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    text-align: center;
    background-color: var(--bgcolour);
    color: #f0f0f0;
    vertical-align: top;
  }
  .ctr h2 {
    top: auto;
    font-family: var(--bigfont);
    font-size: 60px;
    color: var(--coral);
    height: 60px;
    margin-top: 0px;
    padding-top: 0px;
    margin-bottom: 60px;
    text-decoration: underline;
  }
  .ctr2{
    width: 80%;
    display: flex;
    flex-direction: row;
  }
  .ctr3{
    display: flex;
    flex-direction: column;
    margin-top: -15px;
    margin-left: 20px;
    margin-right: 40px;
  }
  .ctr3 p {
    margin-left: 50px;
    margin-top: 0px;
    font-size: 22px;
    margin-bottom: 22px;
  }
  img {
    display: block;
    margin: 0 auto;
    object-fit: cover; 
  }
  .dog {
    width: 25%;
    outline-style: inset;
    outline-width: 15px;
    outline-color: var(--coral);
  }

  @media only screen and (max-width: 1000px) { 
    .ctr h2 {
        margin-bottom: 70px;
    }
    .ctr3{
        margin-right:10px;
    }
    .ctr3 p {
        padding:0px;
        font-size: 20px;
        margin-left: 20px;
        margin-top: 0px;
        margin-bottom: 15px;
    }
  }
  @media only screen and (max-width: 600px) { 
    .about{
        overflow:auto;
    }
    .ctr h2 {
        margin-top: 0px;
        font-size: 35px;
        margin-bottom: 30px;
    }
    .ctr2{
        flex-direction: column;
    }
    .dog {
        width: 60%;
        outline-style: inset;
        outline-width: 15px;
        outline-color: var(--coral);
      }
    .ctr3 {
        margin-top: 30px;
    }
    .ctr3 p {
        font-size: 20px;
        margin-left: 0px;
        margin-right: 0px;
        margin-top: 0px;
        margin-bottom: 15px;
    }
  }
