.navbar {
  width: 100%;
  height: 100px;
  background: var(--bgcolour);
  display: flex;
  font-family: "DM Sans";
  align-items: center;
  justify-content: space-between;
  color: white;
  margin: 0;
}

.site-title {
  font-size: 30px;
  font-weight: bold;
  white-space: nowrap;
  flex-shrink: 0;
  color: white;
  margin-left: 2rem;  
}
.topbar{
  height: 100px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.links {
  font-size: 22px;
  padding: 0;
  list-style: none;
  display: flex;
  gap: 3rem;
  margin-right: 2rem;
}

.navbar a {
  color: white; 
  text-decoration: none; 
  height: 100%;
  display: flex;
  align-items: center;
}
nav a:not(.active):hover {
  color: var(--hover);
}

.toggleButton {
  display: none;
}

.toggleButton svg {
  font-size: 50px;
}

.toggleButton button {
  margin-right: 20px;
  background-color: transparent;
  border: none;
  color: white;
  cursor: pointer;
  position: static;
}

#open {
  height: 100vh;
  flex-direction: column;
  justify-content: flex-start;
}
#open .links {
  padding-top: 100px;
  width: 100%;
  margin: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4rem;
}

@media only screen and (max-width: 900px) {
  .toggleButton {
    display: none;
  }
}

@media only screen and (max-width: 600px) {
  .toggleButton {
    display: flex;
    position: static;
  }
  .links {
    display: none;
  }

  #open.navbar.links {
    display: flex;
    flex-direction: column;

  }
}
